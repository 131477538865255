import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from 'react-router-dom';
import Category from './Category';

function Content() {

	return (
		<main className="content">
			<Switch>
				<Route path="/" exact>
					<h3>anasayfa</h3>
				</Route>
				<Route path="/:category_url" exact>
					<Category />
				</Route>
				<Route path="/:slug/:url">
					<h3>makale içeriği!</h3>
				</Route>
				<Route path="/users">
					<h3>users</h3>
				</Route>
			</Switch>
		</main>
	)
}

export default Content