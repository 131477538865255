import './main.scss';

import {BrowserRouter as Router} from 'react-router-dom';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Content from './components/Content';

function Main() {
	return (
		<Router>
			<Header/>
			<div className="container main-content">
				<Sidebar/>
				<Content/>
			</div>
		</Router>
	);
}

export default Main;
