import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams,
} from 'react-router-dom';
import {useSWR, fetcher} from '../request'

function Category() {

	const {category_url} = useParams()
	const {data, error} = useSWR(`${process.env.REACT_APP_API_URL}/category?url=${category_url}`, fetcher)

	if (!data) return 'loading..'

	const {category, articles: sections} = data

	console.log(sections)

	return (
		<>
			<div className="category-info" style={{'--bgColor': category.category_color}}>
				<h3>{category.category_name}</h3>
				<div className="description" dangerouslySetInnerHTML={{__html: category.category_text}}></div>
			</div>

			<div className="category-sections">
				<ul>
					{Object.keys(sections).map(url => {
						const subCategory = sections[url].category;
						return sections[url].articles.map(article => (
							<li>
								<Link to={`/${category.category_url}/${article.article_url}`}>
									{article.article_title.replace('&lt;', '<').replace('&gt;', '>')}
									<span className="category">{subCategory.sub_title}</span>
								</Link>
							</li>
						))
					})}
				</ul>
			</div>
		</>
	)
}

export default Category