import {useSWR, fetcher} from '../request'
import {Link, useParams, NavLink} from 'react-router-dom';

function Sidebar() {

	const params = useParams()
	console.log(params)
	const {data, error} = useSWR(`${process.env.REACT_APP_API_URL}/categories`, fetcher)

	return (
		<aside className="sidebar">

			<nav>
				<div className="menu">
					<NavLink to="/" exact>
						Anasayfa
					</NavLink>
					<a href="#">Akış</a>
					<a href="#">Keşfet</a>
					<a href="#">Soru & Cevap</a>
				</div>
			</nav>

			<nav>
				<h3>Dersler</h3>
				<div className="menu">
					{data && data.map(category => (
						<NavLink style={{'--bgColor': category.color, '--textColor': '#fff'}} key={category.id} to={`/${category.url}`}>
							{category.name}
						</NavLink>
					))}
				</div>
			</nav>

		</aside>
	)
}

export default Sidebar