import {Link} from 'react-router-dom';

function Header() {
	return (
		<header className="header">
			<div className="container">

				<Link to="/" className="logo">
					./prototurk
					<span className="version">v2.3.1</span>
				</Link>

				<div className="search">
					<input type="text" placeholder="Ara.."/>
				</div>

				<div className="user-actions">
					<a href="#" className="user-btn">Giriş yap</a>
				</div>

			</div>
		</header>
	)
}

export default Header